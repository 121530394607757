import { createGlobalStyle } from 'styled-components';

import 'react-toastify/dist/ReactToastify.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'pretty-checkbox/dist/pretty-checkbox.min.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';
import 'react-day-picker/lib/style.css';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  *:focus,
  ::-moz-focus-inner {
    outline: 0;
    box-shadow: 0;
    outline-width: 0;
  }
  html, body, #root, body.swal2-height-auto {
    min-height: 100vh;
    scroll-behavior: smooth;
    -moz-osx-font-smoothing: grayscale;
  }
  body, input, button {
    font-family:'Roboto', sans-serif;
    font-size: 14px;
    color: #7b7c80;
    font-weight: 400;
  }
  a {
    color: #7159c1;
    font-weight: 400;
    text-decoration: none;
    cursor: pointer;
  }
  ul {
    list-style: none;
  }
  input[type='checkbox'],
  button {
    cursor: pointer;
    outline: 0;
    box-shadow: 0;
    outline-width: 0;
  }
  input,
  textarea,
  button,
  select,
  a {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 50px white inset !important;
  }

  .react-select__menu {
    top: 71% !important;
    background: linear-gradient(-90deg, #7159c1, #ab59c1) !important;

    .react-select__option {
      color: #fff;
    }
    .react-select__option--is-focused {
      background: rgba(0, 0, 0, 0.1) !important;
      color: #fff;
    }
  }

  .react-select__control--is-focused  .react-select__indicators  .react-select__indicator {
    color: #fff;
  }

  .react-select__control {
    background: rgba(0, 0, 0, 0.1) !important;
    border: 0 !important;
    height: 44px;
    color: #fff !important;
    margin: 0 0 10px;

    .react-select__value-container {
      height: 44px;

      .react-select__single-value {
        color: #fff;
        top: 65%;
      }

      .react-select__placeholder {
        color: rgba(255, 255, 255, 0.7);
        top: 65%;
      }

      .react-select__input{
        height: 44px;

        input {
          color: #fff !important;
        }
      }
    }

    .react-select__indicators {
      color: #fff;
      height: 44px;
      .react-select__indicator:hover {
        color: #fff;
      }
      .react-select__indicator-separator {
        height: 0;
      }
    }
  }

  .dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color:  rgba(0,0,0,0.2);
    border-style: dashed;
    background-color: rgba(0,0,0,0.1);
    transition: border .24s ease-in-out;
    color: white;
    outline: none;
  }

  .dropzone:focus {
    border-color: #2196f3;
  }

  .dropzone.disabled {
    opacity: 0.6;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: #7159c1;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color: #5943a2;
  }
`;
