import { Form } from '@unform/web';
import React from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import Checkbox from '~/components/Checkbox';
import Input from '~/components/Input';
import Textarea from '~/components/Textarea';
import api from '~/services/api';
import history from '~/services/history';

import { ActionsBox, Container, Wrapper } from './styles';

export default function Installation({ match }) {
  const id = parseInt(match.params.id, 10);
  const companyProductId = parseInt(match.params.companyProductId, 10);
  const companyId = parseInt(match.params.companyId, 10);
  const [installation, setInstallation] = React.useState(null);

  const formRef = React.useRef(null);

  React.useEffect(() => {
    async function load() {
      if (id > 0) {
        const resp = await api.get(
          `company-product/${companyProductId}/installations/${id}`
        );

        setInstallation({ ...resp.data, code: resp.data.code.toUpperCase() });
      } else {
        setInstallation({});
      }
    }

    load();
  }, [companyProductId, id]);

  async function handleSubmit(data) {
    try {
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        code: Yup.string().required(),
        user_name: Yup.string(),
        pc_name: Yup.string().required(),
      });
      await schema.validate(data, {
        abortEarly: false,
      });

      if (id === -1) {
        await api.post(
          `company-product/${companyProductId}/installations`,
          data
        );
        toast.success('La instalación se ha registrado con éxito!');
      } else {
        await api.put(
          `company-product/${companyProductId}/installations/${id}`,
          data
        );
        toast.success('Datos de instalación actualizados con éxito!');
      }
      history.push(`/company/${companyId}/product/${companyProductId}`);
    } catch (err) {
      const validationErrors = {};

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      } else {
        toast.error(err.response.data.error);
      }
    }
  }

  return (
    <>
      <Wrapper>
        <Container>
          <h1>
            <Link to={`/company/${companyId}/product/${companyProductId}`}>
              <IoIosArrowBack color="#fff" size={32} />
            </Link>
            {id === -1 ? 'Añadir instalación' : 'Actualizar instalación'}
          </h1>

          {installation && (
            <Form
              ref={formRef}
              initialData={installation}
              onSubmit={handleSubmit}
            >
              <Input
                name="code"
                label="Código de instalación"
                placeholder="Nº de serie de instalación"
              />
              <Input
                name="pc_name"
                label="Nombre del ordenador"
                placeholder="El nombre del ordenador"
              />
              <Input
                name="user_name"
                label="Nombre del usuario"
                placeholder="Nombre del usuario"
              />
              <Input
                name="ip_address"
                label="Dirección ip local"
                placeholder="Dirección ip local del ordenador en el momento de su instalación"
              />

              <Checkbox name="is_active" label="Instalación activa" />

              <Textarea
                name="info"
                label="Información del ordenador (cmd > systeminfo)"
                placeholder="Información adicional del sistema"
                rows={40}
              />

              <ActionsBox>
                <button type="submit">GUARDAR</button>
              </ActionsBox>
            </Form>
          )}
        </Container>
      </Wrapper>
    </>
  );
}
