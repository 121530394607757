import { format, isDate, parseISO } from 'date-fns';
import es from 'date-fns/locale/es';

export function formatDate(date) {
  if (!date) return '';

  return format(isDate(date) ? date : parseISO(date), 'dd/MM/yyyy', {
    locale: es,
  });
}

export function formatDateTime(date) {
  if (!date) return '';

  return format(isDate(date) ? date : parseISO(date), 'dd/MM/yyyy HH:mm', {
    locale: es,
  });
}

export function formatShortDate(date) {
  if (!date) return '';

  return format(isDate(date) ? date : parseISO(date), 'dd MMM', {
    locale: es,
  });
}

export function formatMonthDate(date) {
  if (!date) return '';

  return format(isDate(date) ? date : parseISO(date), 'MMMM', {
    locale: es,
  });
}

export function formatLargeDate(date) {
  if (!date) return '';

  return format(isDate(date) ? date : parseISO(date), "EEEE, d 'de' MMMM", {
    locale: es,
  });
}

export function formatLargeDateTime(date) {
  if (!date) return '';

  return format(
    isDate(date) ? date : parseISO(date),
    "d 'de' MMMM 'de' yyyy 'a las' hh':'mm",
    {
      locale: es,
    }
  );
}

export function getDateOfISOWeek(w, y) {
  const simple = new Date(y, 0, 1 + (w - 1) * 7);
  const dow = simple.getDay();
  const ISOweekStart = simple;
  if (dow <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
  else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
  return ISOweekStart;
}

export function getDateStr(date) {
  const offset = date.getTimezoneOffset();
  const result = new Date(date.getTime() - offset * 60 * 1000);

  return result.toISOString().split('T')[0];
}
