import React, { useState, useEffect } from 'react';
import { MdAdd } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { formatDate } from '../../utils/DateUtils';
import api from '~/services/api';

import {
  Container,
  UpdatesContainer,
  UpdateItem,
  Actions,
  AddLink,
} from './styles';

export default function UpdatesProduct({ match }) {
  const [updates, setUpdates] = useState([]);
  const [filter, setFilter] = useState('');
  const [product, setProduct] = useState(null);

  const product_id = match.params.id;

  useEffect(() => {
    async function load() {
      const [response, productResponse] = await Promise.all([
        api.get(`updates/${product_id}`),
        api.get(`products/${product_id}`),
      ]);

      const data = response.data.map((item) => {
        return {
          id: item.id,
          version: item.version,
          is_beta: item.is_beta,
          start_at: formatDate(item.start_at),
        };
      });

      setUpdates(data);
      setProduct(productResponse.data);
    }

    load();
  }, [product_id]);

  const updatesFiltered = updates.filter(
    (c) =>
      filter.trim() === '' ||
      c.version.toLocaleLowerCase().indexOf(filter.trim().toLocaleLowerCase()) >
      -1
  );

  return (
    <Container>
      <Actions>
        <input
          placeholder="Buscar..."
          value={filter}
          onChange={(e) => {
            setFilter(e.target.value);
          }}
        />
        <AddLink to={`/updates/${product_id}/product/-1`}>
          <MdAdd size={24} color="#fff" /> NUEVA
        </AddLink>
      </Actions>
      <UpdatesContainer>
        <header>{product && <h1>{product.name}</h1>}</header>
        {updatesFiltered.map((update) => {
          return (
            <UpdateItem key={update.id}>
              <div>
                <Link to={`/updates/${product_id}/product/${update.id}`}>
                  <strong>{update.version}</strong>
                </Link>
                <p>Fecha actualización: {update.start_at}</p>
              </div>

              {update.is_beta && <span>BETA</span>}
            </UpdateItem>
          );
        })}
      </UpdatesContainer>
    </Container>
  );
}
