import { Form } from '@unform/web';
import { parseISO } from 'date-fns';
import React from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import DatePicker from '~/components/DatePicker';
import Input from '~/components/Input';
import ListOfInstallations from '~/components/ListOfInstallations';
import Select from '~/components/Select';
import api from '~/services/api';
import history from '~/services/history';

import { ActionsBox, Container, Wrapper } from './styles';

const modulesNames = {
  comercializadora: 'Comercializadora',
  mercado: 'Mercado',
  campo: 'Campo',
  bodega: 'Bodega',
  almazara: 'Almazara',
  fabricacion: 'Fabricación',
  contabilidad: 'Contabilidad',
  tpv: 'TPV',
  confeccion: 'Confección',
  enlaces_externos: 'Enlaces externos',
  yusbu: 'Yusbu',
  regs: 'Regs',
};

const moduleOptions = [
  { value: 'comercializadora', label: 'Comercializadora' },
  { value: 'mercado', label: 'Mercado' },
  { value: 'campo', label: 'Campo' },
  { value: 'bodega', label: 'Bodega' },
  { value: 'almazara', label: 'Almazara' },
  { value: 'fabricacion', label: 'Fabricación' },
  { value: 'contabilidad', label: 'Contabilidad' },
  { value: 'tpv', label: 'TPV' },
  { value: 'confeccion', label: 'Confección' },
  { value: 'enlaces_externos', label: 'Enlaces externos' },
  { value: 'yusbu', label: 'Yusbu' },
  { value: 'regs', label: 'Regs' },
];

export default function CompanyProduct({ match }) {
  const id = parseInt(match.params.id, 10);
  const companyId = parseInt(match.params.companyId, 10);
  const [products, setProducts] = React.useState([]);
  const [allowedUpdatesAt, setAllowedUpdatesAt] = React.useState(null);
  const [selectedProd, setSelectedProd] = React.useState(null);
  const [selectedModules, setSelectedModules] = React.useState([]);
  const [companyProduct, setCompanyProduct] = React.useState(null);

  const formRef = React.useRef(null);

  React.useEffect(() => {
    async function load() {
      const response = await api.get('products');
      const options = response.data.map((prod) => ({
        value: prod.id,
        label: prod.name,
      }));

      await setProducts(options);

      if (id > 0) {
        const resp = await api.get(`companies/${companyId}/products/${id}`);
        const newSelectedProd = {
          value: resp.data.product_id,
          label: resp.data.product.name,
        };

        const data = {
          ...resp.data,
          product: newSelectedProd,
        };

        const currentSelectedModules = resp.data.Modules.map((item) => ({
          value: item.module,
          label: modulesNames[item.module],
        }));

        setSelectedProd(newSelectedProd);
        setSelectedModules(currentSelectedModules);
        setCompanyProduct(data);
        if (data.allowed_updates_at) {
          setAllowedUpdatesAt(parseISO(data.allowed_updates_at));
        }
      } else {
        setCompanyProduct({});
        setSelectedProd(options[0]);
      }
    }

    load();
  }, [companyId, id]);

  async function handleSubmit(data) {
    try {
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        product: Yup.number().required(),
        concurrent_users: Yup.number().required(),
        allowed_facilities: Yup.number().required(),
        allowed_updates_at: Yup.string().nullable(),
        modules: Yup.array().of(Yup.string()),
      });
      await schema.validate(data, {
        abortEarly: false,
      });

      if (id === -1) {
        const response = await api.post(`companies/${companyId}/products`, {
          ...data,
          product_id: data.product,
        });
        toast.success('Producto se ha asignado con éxito!');
        history.push(`/company/${companyId}/product/${response.data.id}`);
      } else {
        await api.put(`companies/${companyId}/products/${id}`, {
          ...data,
          product_id: data.product,
        });
        toast.success('Datos de producto actualizado con éxito!');
      }
    } catch (err) {
      const validationErrors = {};

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      } else {
        toast.error(err.response.data.error);
      }
    }
  }

  return (
    <>
      <Wrapper>
        <Container>
          <h1>
            <Link to={`/company/${companyId}`}>
              <IoIosArrowBack color="#fff" size={32} />
            </Link>
            {id === -1 ? 'Añadir producto' : 'Actualizar producto'}
          </h1>

          {companyProduct && (
            <Form
              ref={formRef}
              initialData={companyProduct}
              onSubmit={handleSubmit}
            >
              <Select
                name="product"
                label="Producto"
                placeholder="Seleccionar producto"
                options={products}
                defaultValue={selectedProd}
                autoFocus
              />
              <Input
                name="concurrent_users"
                label="Nº de usuarios concurrentes"
                placeholder="Asignar nº de usuarios concurrentes"
              />
              <Input
                name="allowed_facilities"
                label="Nº de instalaciones activas permitidas"
                placeholder="Asignar nº de instalaciones activas permitidas"
              />
              <DatePicker
                label="Actualizaciones hasta"
                name="allowed_updates_at"
                selected={allowedUpdatesAt}
                onChange={(date) => setAllowedUpdatesAt(date)}
              />

              <Select
                name="modules"
                label="Módulos"
                placeholder="Seleccionar módulos"
                options={moduleOptions}
                defaultValue={selectedModules}
                isMulti
              />

              <ActionsBox>
                <button type="submit">GUARDAR</button>
              </ActionsBox>
            </Form>
          )}
        </Container>

        {id > 0 && <ListOfInstallations id={id} companyId={companyId} />}
      </Wrapper>
    </>
  );
}
