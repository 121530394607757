import { Form } from '@unform/web';
import { parseISO } from 'date-fns';
import React from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { IoIosArrowBack } from 'react-icons/io';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import DatePicker from '~/components/DatePicker';

import Input from '~/components/Input';
import ListOfProducts from '~/components/ListOfProducts';
import api from '~/services/api';
import history from '~/services/history';

import { Container, DeleteBox, Wrapper } from './styles';

export default function Company({ match }) {
  const [company, setCompany] = React.useState(null);
  const [fecha, setFecha] = React.useState(null);
  const formRef = React.useRef(null);

  React.useEffect(() => {
    async function load() {
      const id = parseInt(match.params.id, 10);
      if (id > 0) {
        const response = await api.get(`companies/${id}?page=-1`);
        setCompany(response.data);

        if (response.data.fecha) {
          setFecha(parseISO(response.data.fecha));
        }
      } else {
        setCompany({ code: '', name: '' });
      }
    }

    load();
  }, [match.params.id]);

  async function handleSubmit(data) {
    try {
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        name: Yup.string().required(),
        factura: Yup.string().nullable(),
        fecha: Yup.string().nullable(),
      });
      await schema.validate(data, {
        abortEarly: false,
      });

      const id = parseInt(match.params.id, 10);
      if (id === -1) {
        const response = await api.post('companies', data);
        toast.success('Empresa creada con éxito!');
        history.push(`/company/${response.data.id}`);
      } else {
        await api.put(`companies/${id}`, data);
        toast.success('Empresa actualizada con éxito!');
      }
    } catch (err) {
      const validationErrors = {};

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      } else if (err && err.response) {
        toast.error(err.response.data.error);
      }
    }
  }

  function handleDeleteCompany(e) {
    e.preventDefault();

    confirmAlert({
      title: 'Eliminar empresa',
      message: `¿Seguro que desea eliminar la empresa ${company.name}?`,
      buttons: [
        {
          label: 'Continuar',
          onClick: async () => {
            const id = parseInt(match.params.id, 10);
            if (id > 0) {
              await api.delete(`companies/${id}`);
              toast.success('Empresa eliminada con éxito!');
              history.push('/dashboard');
            }
          },
        },
        {
          label: 'Cancelar',
        },
      ],
    });
  }

  return (
    <>
      <Wrapper>
        <Container>
          <h1>
            <Link to="/dashboard">
              <IoIosArrowBack color="#fff" size={32} />
            </Link>
            {parseInt(match.params.id, 10) === -1
              ? 'Nueva empresas'
              : 'Modificar empresa'}
          </h1>
          {company && (
            <Form ref={formRef} initialData={company} onSubmit={handleSubmit}>
              <Input
                name="code"
                label="Código de empresa"
                placeholder="Código"
                disabled={parseInt(match.params.id, 10) > 0}
              />

              <Input
                name="name"
                label="Nombre de la empresa"
                placeholder="Nombre de la empresa"
                autoFocus
              />

              <Input
                name="factura"
                label="Factura de la empresa"
                placeholder="Factura de la empresa"                
              />


              <DatePicker
                label="Fecha"
                name="fecha"
                selected={fecha}
                onChange={(date) => setFecha(date)}
              />

              <div className="actions">
                <button type="submit">GUARDAR</button>
              </div>
            </Form>
          )}
        </Container>
      </Wrapper>
      {parseInt(match.params.id, 10) !== -1 && (
        <ListOfProducts companyId={parseInt(match.params.id, 10)} />
      )}
      {parseInt(match.params.id, 10) !== -1 && (
        <Wrapper>
          <Container>
            <DeleteBox>
              <h1>Eliminar empresa</h1>
              <p>
                Una vez que elimine la empresa, no hay vuelta atrás. Por favor,
                esté seguro
              </p>
              <button
                type="button"
                title="Eliminar empresa"
                onClick={handleDeleteCompany}
              >
                Eliminar empresa
              </button>
            </DeleteBox>
          </Container>
        </Wrapper>
      )}
    </>
  );
}
