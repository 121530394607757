import React, { useRef, useState, useEffect } from 'react';
import ReactDatePicker from 'react-datepicker';
import es from 'date-fns/locale/es';
import { useField } from '@unform/core';

import 'react-datepicker/dist/react-datepicker.css';
import { Container } from './styles.js';

export default function DatePicker({ label, name, ...rest }) {
  const datepickerRef = useRef(null);
  const { fieldName, registerField, defaultValue } = useField(name);
  const [date, setDate] = useState(defaultValue || null);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datepickerRef.current,
      path: 'props.selected',
      clearValue: (ref) => {
        ref.clear();
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      {label && <label htmlFor={name}>{label}</label>}
      <ReactDatePicker
        ref={datepickerRef}
        selected={date}
        onChange={setDate}
        dateFormat="dd/MM/yyyy"
        locale={es}
        {...rest}
      />
    </Container>
  );
}
