import React, { useState, useEffect, useRef } from 'react';
import { parseISO } from 'date-fns';
import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { IoIosArrowBack } from 'react-icons/io';

import history from '~/services/history';
import api from '~/services/api';
import Input from '~/components/Input';
import DatePicker from '~/components/DatePicker';
import ReactDropzoneInput from '~/components/ReactDropzoneInput';
import Checkbox from '~/components/Checkbox';

import { Wrapper, Container, DeleteBox, FileContainer } from './styles';

export default function Update({ match }) {
  const [update, setUpdate] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const formRef = useRef(null);

  const { product_id, id } = match.params;

  useEffect(() => {
    async function load() {
      if (id === '-1') {
        setUpdate({
          version: '',
          is_beta: false,
          start_at: new Date(),
        });
      } else {
        const response = await api.get(`/updates/${id}/update`);
        setUpdate(response.data);
        setStartDate(parseISO(response.data.start_at));
      }
    }

    load();
  }, [id]);

  async function handleSubmit(data) {
    try {
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        version: Yup.string()
          .trim()
          .matches(/[0-9]*.[0-9]*.[0-9]*.[0-9]*/, 'El formato no es válido')
          .required(),
        is_beta: Yup.boolean().required(),
        start_at: Yup.string(),
      });
      await schema.validate(data, {
        abortEarly: false,
      });

      let file_id = update?.file?.id;
      const { version, is_beta, start_at } = data;

      if (data.files && data.files.length > 0) {
        const filename = data.files[0].name;

        const formData = new FormData();
        formData.append('file', data.files[0]);

        try {
          const response = await api.post('/files', formData);
          file_id = response.data.id;
        } catch (error) {
          if (error.response && error.response.data) {
            const { message } = error.response.data.error;

            if (message === 'File too large') {
              toast.error(
                `El archivo ${filename} es demasiado grande para poder subirlo.`
              );
            } else if (message === 'Internal server error') {
              toast.error(`El formato del archivo ${filename} no es valido`);
            } else {
              toast.error(`El archivo ${filename} no se ha podido subir.`);
            }
          } else {
            toast.error(`El archivo ${filename} no se ha podido subir.`);
          }
        }
      }

      if (id === '-1') {
        await api.post(`/updates/${product_id}`, {
          version,
          is_beta,
          start_at,
          file_id,
        });
        toast.success('Actualización creada con éxito!');
      } else {
        await api.put(`updates/${id}/update`, {
          version,
          is_beta,
          start_at,
          file_id,
        });
        toast.success('Actualización actualizada con éxito!');
      }
      history.push(`/updates/${product_id}/product`);
    } catch (err) {
      const validationErrors = {};

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });

        formRef.current.setErrors(validationErrors);
      } else if (err && err.response) {
        toast.error(err.response.data.error);
      }
    }
  }

  function handleDeleteUpdate(e) {
    e.preventDefault();

    confirmAlert({
      title: 'Eliminar actualización',
      message: `¿Seguro que desea eliminar la actualización ${update.version}?`,
      buttons: [
        {
          label: 'Continuar',
          onClick: async () => {
            if (id !== '-1') {
              await api.delete(`/updates/${id}/update`);
              toast.success('Actualización eliminada con éxito!');

              history.push(`/updates/${product_id}/product`);
            }
          },
        },
        {
          label: 'Cancelar',
        },
      ],
    });
  }

  return (
    <>
      <Wrapper>
        <Container>
          <h1>
            <Link to={`/updates/${product_id}/product`}>
              <IoIosArrowBack color="#fff" size={32} />
            </Link>
            {parseInt(id, 10) === -1
              ? 'Nueva actualización'
              : 'Modificar actualización'}
          </h1>
          {update && (
            <Form ref={formRef} initialData={update} onSubmit={handleSubmit}>
              <Input
                name="version"
                label="Versión de la actualización"
                placeholder="21.1.0.1"
                pattern="[0-9]*.[0-9]*.[0-9]*.[0-9]*"
                autoFocus
              />

              <DatePicker
                label="Fecha de actualización"
                name="start_at"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
              />

              <Checkbox name="is_beta" label="Versión beta" />

              {update?.file && (
                <FileContainer>
                  <strong>{update.file.name}</strong>
                  <a href={update.file.url} download>
                    Descargar
                  </a>
                </FileContainer>
              )}

              <ReactDropzoneInput name="files" />

              <div className="actions">
                <button type="submit">GUARDAR</button>
              </div>
            </Form>
          )}
        </Container>
      </Wrapper>

      {parseInt(id, 10) !== -1 && (
        <Wrapper>
          <Container>
            <DeleteBox>
              <h1>Eliminar actualización</h1>
              <p>
                Una vez que elimine la actualización, no hay vuelta atrás. Por
                favor, esté seguro
              </p>
              <button
                type="button"
                title="Eliminar actualización"
                onClick={handleDeleteUpdate}
              >
                Eliminar actualización
              </button>
            </DeleteBox>
          </Container>
        </Wrapper>
      )}
    </>
  );
}
