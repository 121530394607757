import { useField } from '@unform/core';
import React from 'react';
import { useDropzone } from 'react-dropzone';

import { Container } from './styles';

export default function ReactDropzoneInput({ name }) {

  const inputRef = React.useRef(null);
  const { fieldName, registerField, defaultValue = [] } = useField(name);
  const [acceptedFiles, setAcceptedFiles] = React.useState(defaultValue);

  const onDrop = React.useCallback(onDropAcceptedFiles => {

    if (inputRef.current) {
      inputRef.current.acceptedFiles = onDropAcceptedFiles;
      setAcceptedFiles(onDropAcceptedFiles);
    }
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({onDrop})

  React.useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: (ref) => {
        return ref.acceptedFiles || [];
      },
      clearValue: (ref) => {
        ref.acceptedFiles = [];
        setAcceptedFiles([]);
      },
      setValue: (ref, value) => {
        ref.acceptedFiles = value;
        setAcceptedFiles(value);
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container
      {...getRootProps()}
      onClick={() => inputRef.current?.click()}
      className="dropzone"
    >
      <input {...getInputProps()} accept="application/zip" ref={inputRef} />
      {acceptedFiles.length !== 0 && (
        <ul>
          {acceptedFiles.map((file) => (
            <li key={file.name}>{file.name}</li>
          ))}
        </ul>
      )}

      {isDragActive ? (
        <p>Suelta el archivo aquí...</p>
      ) : (
        <p>
          Arrastre y suelte el archivo de actualización aquí o haga clic para
          seleccionar archivos
        </p>
      )}
    </Container>
  );
}
