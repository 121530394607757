import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import api from '~/services/api';

import { Container, ProductsContainer, ProductItem } from './styles';

export default function Updates() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    async function load() {
      const response = await api.get('products');
      setProducts(response.data);
    }

    load();
  }, []);

  return (
    <Container>
      <ProductsContainer>
        {products.map((product) => {
          return (
            <ProductItem key={product.id}>
              <Link to={`updates/${product.id}/product`}>
                <strong>{product.name}</strong>
              </Link>
            </ProductItem>
          );
        })}
      </ProductsContainer>
    </Container>
  );
}
