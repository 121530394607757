import {
  format,
  isAfter,
  isBefore,
  isDate,
  parseISO,
  setHours,
  setMilliseconds,
  setMinutes,
  setSeconds,
} from 'date-fns';
import React from 'react';

import es from 'date-fns/locale/es';

import { confirmAlert } from 'react-confirm-alert'; // Import
import { MdAdd, MdInbox } from 'react-icons/md';
import { Link } from 'react-router-dom';

import api from '~/services/api';

import {
  AddInstallation,
  Container,
  EmptyBox,
  HeaderButton,
  List,
  ListHeader,
  ListItem,
  Wrapper,
} from './styles';

function formatDateTime(date) {
  if (!date) return '';

  return format(isDate(date) ? date : parseISO(date), 'dd/MM/yyyy HH:mm', {
    locale: es,
  });
}

function orderByField(array, column, order) {
  function orderBy(a, b) {
    switch (column) {
      case 'pc_name': {
        const aName = a.pc_name;
        const bName = b.pc_name;

        if (order === 'desc') {
          if (aName > bName) return -1;
          if (bName > aName) return 1;
        } else {
          if (aName < bName) return -1;
          if (bName < aName) return 1;
        }
        break;
      }
      case 'code': {
        const aName = a.code;
        const bName = b.code;

        if (order === 'desc') {
          if (aName > bName) return -1;
          if (bName > aName) return 1;
        } else {
          if (aName < bName) return -1;
          if (bName < aName) return 1;
        }
        break;
      }
      case 'createdAt': {
        const aCreatedAt = setHours(
          setMinutes(
            setSeconds(setMilliseconds(parseISO(a.createdAt), 0), 0),
            0
          ),
          0
        );
        const bCreatedAt = setHours(
          setMinutes(
            setSeconds(setMilliseconds(parseISO(b.createdAt), 0), 0),
            0
          ),
          0
        );

        if (order === 'desc') {
          if (isAfter(aCreatedAt, bCreatedAt)) return -1;
          if (isAfter(bCreatedAt, aCreatedAt)) return 1;
        } else {
          if (isBefore(aCreatedAt, bCreatedAt)) return -1;
          if (isBefore(bCreatedAt, aCreatedAt)) return 1;
        }
        break;
      }
      case 'updatedAt': {
        const aUpdatedAt = setHours(
          setMinutes(
            setSeconds(setMilliseconds(parseISO(a.updatedAt), 0), 0),
            0
          ),
          0
        );
        const bUpdatedAt = setHours(
          setMinutes(
            setSeconds(setMilliseconds(parseISO(b.updatedAt), 0), 0),
            0
          ),
          0
        );

        if (order === 'desc') {
          if (isAfter(aUpdatedAt, bUpdatedAt)) return -1;
          if (isAfter(bUpdatedAt, aUpdatedAt)) return 1;
        } else {
          if (isBefore(aUpdatedAt, bUpdatedAt)) return -1;
          if (isBefore(bUpdatedAt, aUpdatedAt)) return 1;
        }
        break;
      }
      default:
    }
    return 0;
  }

  return array.sort(orderBy);
}

export default function ListOfInstallations({ id, companyId }) {
  const [installations, setInstallations] = React.useState([]);
  const [filter, setFilter] = React.useState('');
  const [searchValue, setSearchValue] = React.useState('');
  const [sorted, setSorted] = React.useState({ column: 'updatedAt', order: 'desc' });

  React.useEffect(() => {
    async function load() {
      const response = await api.get(`company-product/${id}/installations`);

      setInstallations(response.data);
    }

    load();
  }, [id]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setFilter(searchValue);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [searchValue]);

  const filteredItems = React.useMemo(() => {
    const term = filter.trim().toLocaleLowerCase();

    const filteredInstallations = installations.filter(
      (item) =>
        filter === '' ||
        item.pc_name.toLocaleLowerCase().indexOf(term) > -1 ||
        item.user_name?.toLocaleLowerCase().indexOf(term) > -1 ||
        item.code.toLocaleLowerCase().indexOf(term) > -1
    );

    return orderByField(filteredInstallations, sorted.column, sorted.order);
  }, [installations, filter, sorted]);

  function handleDelete(item) {
    confirmAlert({
      title: 'Eliminar instalación',
      message: `¿Seguro que desea eliminar la instalacion ${item.code}?`,
      buttons: [
        {
          label: 'Continuar',
          onClick: async () => {
            await api.delete(`company-product/${id}/installations/${item.id}`);
            setInstallations((oldItems) =>
              oldItems.filter((el) => el.id !== item.id)
            );
          },
        },
        {
          label: 'Cancelar',
        },
      ],
    });
  }

  function handleSort(newColumn) {
    let { column, order } = sorted;

    if (column !== newColumn) {
      column = newColumn;
      order = 'asc';
    } else {
      order = order === 'asc' ? 'desc' : 'asc';
    }

    setSorted({ column, order });
  }

  return (
    <Wrapper>
      <Container>
        <header>
          <div>
            <h1>Instalaciones ({filteredItems.length})</h1>
            <AddInstallation
              to={`/company/${companyId}/product/${id}/installation/-1`}
            >
              <MdAdd size={24} color="#fff" /> AÑADIR
            </AddInstallation>
          </div>          
          <input
            placeholder="Buscar..."
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
          />          
        </header>

        {filteredItems.length === 0 ? (
          <EmptyBox>
            <MdInbox size={64} color="#fff" />
            No hay instalaciones
          </EmptyBox>
        ) : (
          <List>
            <ListHeader>
              <HeaderButton
                className="column-2"
                isOrdered={sorted.column === 'pc_name'}
                orderDirection={sorted.order}
                onClick={() => handleSort('pc_name')}
              >
                Equipo
              </HeaderButton>
              <HeaderButton
                className="column-2"
                isOrdered={sorted.column === 'user_name'}
                orderDirection={sorted.order}
                onClick={() => handleSort('user_name')}
              >
                Nombre
              </HeaderButton>
              <HeaderButton
                className="column-2"
                isOrdered={sorted.column === 'code'}
                orderDirection={sorted.order}
                onClick={() => handleSort('code')}
              >
                Serial
              </HeaderButton>
              <HeaderButton
                className="column-2"
                isOrdered={sorted.column === 'updatedAt'}
                orderDirection={sorted.order}
                onClick={() => handleSort('updatedAt')}
              >
                F. Actualización
              </HeaderButton>
              <div className="column-5">Eliminar</div>
            </ListHeader>
            {filteredItems.map((item) => (
              <ListItem key={item.id} isActive={item.is_active ? 1 : 0}>
                <Link
                  to={`/company/${companyId}/product/${id}/installation/${item.id}`}
                  title="Modificar producto"
                  className="column-2"
                >
                  {item.pc_name}
                </Link>
                <div className="column-2">{item.user_name?.toUpperCase()}</div>
                <div className="column-2">{item.code.toUpperCase()}</div>                
                <div className="column-2">{formatDateTime(item.updatedAt)}</div>
                <button
                  type="button"
                  title="Eliminar producto"
                  className="column-5"
                  onClick={() => {
                    handleDelete(item);
                  }}
                >
                  Eliminar
                </button>
              </ListItem>
            ))}
          </List>
        )}
      </Container>
    </Wrapper>
  );
}
