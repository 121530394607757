import React from 'react';
import { FiActivity, FiEdit } from 'react-icons/fi';
import { MdAdd } from 'react-icons/md';
import { Link } from 'react-router-dom';

import api from '~/services/api';

import { formatDate } from '~/utils/DateUtils';
import { ActionLinks, Actions, AddLink, Company, Container } from './styles';

export default function Dashboard() {
  const [companies, setCompanies] = React.useState([]);
  const [filter, setFilter] = React.useState('');

  React.useEffect(() => {
    async function load() {
      const response = await api.get('companies?page=-1');

      setCompanies(response.data);
    }

    load();
  }, []);

  return (
    <Container>
      <Actions>
        <input
          placeholder="Buscar..."
          value={filter}
          onChange={(e) => {
            setFilter(e.target.value);
          }}
        />
        <AddLink to="company/-1">
          <MdAdd size={24} color="#fff" /> REGISTRAR
        </AddLink>
      </Actions>
      <ul>
        {companies
          .filter(
            (c) =>
              filter.trim() === '' ||
              c.name
                .toLocaleLowerCase()
                .indexOf(filter.trim().toLocaleLowerCase()) > -1 ||
              (c.factura && c.factura
                .toLocaleLowerCase()
                .indexOf(filter.trim().toLocaleLowerCase()) > -1)
          )
          .map((company) => (
            <Company key={company.id}>
              <div>
                <strong>{company.name}</strong>
                <p>{company.factura && `Factura: ${company.factura}`}  {company.fecha && `Fecha: ${formatDate(company.fecha)}`} </p>
              </div>
              <ActionLinks>
                <Link to={`company-activity/${company.id}`} title="Actividad">
                  <FiActivity size={20} />
                </Link>
                <Link to={`company/${company.id}`} title="Modificar">
                  <FiEdit size={20} />
                </Link>
              </ActionLinks>
            </Company>
          ))}
      </ul>
    </Container>
  );
}
