import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { darken } from 'polished';

export const Container = styled.div`
  max-width: 1200px;
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  padding: 0 12px;

  @media (min-width: 620px) {
    padding: 0;
  }
`;

export const UpdatesContainer = styled.ul`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 15px;
  margin-top: 30px;

  header {
    color: white;
  }
`;

export const UpdateItem = styled.li`
  padding: 20px;
  border-radius: 4px;
  background: #fff;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;

  > div {
    font-size: 18px;

    > p {
      margin-top: 8px;
      font-size: 14px;
    }
  }

  > span {
    background: #7159c1;
    font-size: 12px;
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    font-weight: bold;
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  input {
    background: rgba(0, 0, 0, 0.1);
    border: 0;
    border-radius: 4px;
    height: 44px;
    padding: 0 15px;
    color: #fff;
    min-width: 300px;
    &::placeholder {
      color: rgba(255, 255, 255, 0.7);
    }
  }
`;

export const AddLink = styled(Link)`
  display: flex;
  align-items: center;
  padding: 12px 24px;
  background: #3b9eff;
  border-radius: 4px;
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  transition: background 0.2s;
  &:hover {
    background: ${darken(0.05, '#3b9eff')};
  }
`;
