import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import logo from '~/assets/logo.png';
import { Container, Content, Profile } from './styles';

export default function Header() {
  const profile = useSelector((state) => state.user.profile);

  return (
    <Container>
      <Content>
        <nav>
          <img src={logo} alt="Control de licencias" />
          <Link to="/dashboard">DASHBOARD</Link>
          <Link to="/updates">ACTUALIZACIONES</Link>
        </nav>

        <aside>
          <Profile>
            <div>
              <strong>{profile.name}</strong>
              <Link to="/profile">Mi Perfil</Link>
            </div>
          </Profile>
        </aside>
      </Content>
    </Container>
  );
}
